<script lang="ts">
	import { FormCard } from "$lib/components/organisms/Card";


  export let useWrapper = false
</script>

{#if useWrapper}
  <FormCard>
    <slot />
  </FormCard>
{:else}
  <slot />
{/if}
